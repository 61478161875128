<template>
    <Header/>
    <Parkingheader/>
    <Parkingservice/>
    <Parkingicons/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Parkingservice from '@/components/Parkingservice.vue'
import Parkingicons from '@/components/Parkingicons.vue'
import Parkingheader from '@/components/Parkingheader.vue'

export default {
  components: {
     Header,
    Footer,
    Parkingservice,
    Parkingicons,
    Parkingheader
  }
}
</script>