<template>
    <section id="parkingicon" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4>Parking Management</h4>
          </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="embedimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <h6 class="embservice">{{ obj.subtitle }}</h6>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
         path: 'background/flexibility.png',
        header: 'FLEXIBILITY',
        subtitle: 'Be ready for Change',
        caption: 'Parking is a requirement that should always be flexible; able to accommodate enough amount of vehicles inflow and out flow.'
      },
      {
         path: 'background/PRICING.png',
        header: 'VALID PRICING',
        subtitle: 'Charges made to validate parking',
        caption: 'The charges of parking are ensured to be paid directly by the users of the space with a measure of the timings.'
      },
      {
         path: 'background/PEAK-HOUR.png',
        header: 'PEAK HOUR MANAGEMENT',
        subtitle: 'Maintain rush hour',
        caption: 'The most important aspect is to maintain rush hour traffic inflow.'
      },
      {
         path: 'background/USERS-KNOWLEDGE.png',
        header: 'USERS KNOWLEDGE',
        subtitle: 'Know your parking space',
        caption: 'Prior Knowledge of the opportunities to park can lead to organization among both motorists and car parking.'
      },
      {
         path: 'background/EFFECTIVE-UTILIZATION.png',
        header: 'EFFECTIVE UTILIZATION',
        subtitle: 'Maximum usage',
        caption: 'The space allotted for the purpose of parking, is used to its full potential. With effective planning and strategizing.'
      },
      {
         path: 'background/CONSUMERS-OPTIONS.png',
        header: 'CONSUMERS OPTIONS',
        subtitle: 'Necessary tool',
        caption: 'It is by far a necessity to have a separate lot for the purposes of parking in all business, homes and common places. Providing maximum options and promoting co- ordination.'
      }
    ]

  })
    
}
</script>
<style>
.services .icon-box img {
    float: left;
    margin-top: 10px;
  }
img.embedimage {
    width: 15%;
}
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#parkingicon {
    padding: 50px 30px 10px 30px;
}


.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
    margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>